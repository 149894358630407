const it = {
    translation: {
        'sidebar.role.admin': 'Amministrazione',
        'sidebar.role.externalAgency': 'Agenzia esterna',
        'sidebar.role.producer': 'Produttori di ricerca',
        'sidebar.role.consumer': 'Consumatori della ricerca',
        'sidebar.role.management': 'Com. di gestione e indirizzo',
        'sidebar.statistics': 'Statistiche',
        'sidebar.demandStatistics': 'Statistiche fabbisogni',
        'sidebar.products': 'Prodotti',
        'sidebar.services': 'Servizi',
        'sidebar.users': 'Utenti',
        'sidebar.discussionTables': 'Tavoli di discussione',
        'sidebar.calls': 'Bandi',
        'sidebar.challenges': 'Sfide',
        'sidebar.demands': 'Fabbisogni',
        'sidebar.payments': 'Contributi',
        'sidebar.keywords': 'Parole chiave',
        'sidebar.categories': 'Categorie',
        'sidebar.settings': 'Impostazioni',
        'table.products': 'Prodotti',
        'table.challenges': 'Sfide',
        'table.operations': 'Operazioni',
        'modal.button.close': 'Chiudi',
        'modal.button.confirm': 'Conferma',
        'modal.button.edit': 'Modifica',
        'modal.button.downloadModel': 'Scarica modello proposta',
        'file.select': 'Seleziona file',
        'file.download': 'Scarica',  
        'file.empty': 'Non e\' presente nessun documento.\nConsentiti solo $$$FORMATS$$$ di massimo 1MB.',
        'file.available': 'E\' disponibile un documento di dettaglio.',
        'demand.title.new': 'Crea nuovo fabbisogno',
        'demand.title.edit': 'Modifica fabbisogno',
        'demand.title.details': 'Dettagli fabbisogno',
        'product.title.new': 'Crea nuovo prodotto',
        'product.title.edit': 'Modifica prodotto',
        'product.title.details': 'Dettagli prodotto',
        'product.producer': 'Produttore proponente',
        'product.name': 'Nome prodotto',
        'product.keyword': 'Parole chiave',
        'product.description': 'Descrizione',
        'product.startDate': 'Data di inizio',
        'product.duration': 'Durata (in giorni)',
        'product.endDate': 'Data di fine',
        'product.trl': 'TRL',
        'product.financeType': 'Tipo di finanziamento',
        'product.productType': 'Tipo di prodotto',
        'product.website': 'Sito web',
        'challenge.title.new': 'Crea nuova sfida',
        'challenge.title.edit': 'Modifica sfida',
        'challenge.title.details': 'Dettagli sfida',
        'challenge.name': 'Nome',
        'challenge.budget': 'Budget',
        'challenge.type': 'Tipologia',
        'challenge.startDate': 'Data di inizio',
        'challenge.endDate': 'Data di scadenza',
        'challenge.description': 'Descrizione',
        'challenge.keyword': 'Parole chiave',
        'challenge.producers': 'Produttori di ricerca',
        'challenge.applicant': 'Consumatore',
        
    }
};

export { it };
